@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
    html {
        font-family: Cardo  , serif;
    }
} */